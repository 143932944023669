<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>
          <span @click="onBack">订单查看</span>
        </BreadcrumbItem>
        <BreadcrumbItem>收发货详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <div class="switch__container">
        <div>{{ deliver }}</div>
        <a-icon type="swap" class="icon__switch" @click="onSwitch" />
        <div>{{ receiver }}</div>
      </div>
      <a-tabs default-active-key="0">
        <a-tab-pane
          :tab="`第${changeNumToString(index + 1)}批明细`"
          v-for="(item, index) in deliverList"
          :key="index"
        >
          <a-form class="advanced-search-form">
            <a-row>
              <a-col :span="8">
                <a-form-item label="发货时间">
                  {{
                    item.logisticsSendTime
                      ? tsFormat(item.logisticsSendTime)
                      : "-"
                  }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="物流公司">
                  {{ item.logisticsName ? item.logisticsName : "-" }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="物流单号">
                  {{ item.logisticsSn ? item.logisticsSn : "-" }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="8">
                <a-form-item label="物流电话">
                  {{ item.logisticsTel ? item.logisticsTel : "-" }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="发货清单">
                  <a-table
                    :columns="columns"
                    :data-source="calculate(item.detailVoList)"
                    :loading="loading"
                    :pagination="false"
                    rowKey="skuId"
                  >
                    <template slot="goodsSn" slot-scope="text, record">
                      <span v-if="record.skuCode === '合计：'"></span>
                      <span v-else>{{ text ? text : "-" }}</span>
                    </template>
                  </a-table>
                </a-form-item>
              </a-col>
            </a-row>
            <div v-if="item.receiveBill && item.receiveBill.length > 0">
              <div v-for="ele in item.receiveBill" :key="ele.receivingId">
                <a-row>
                  <a-col :span="8">
                    <a-form-item label="收货时间">
                      {{ ele.signTime ? tsFormat(ele.signTime) : "-" }}
                    </a-form-item>
                  </a-col>
                  <a-col :span="8">
                    <a-form-item label="发票信息">
                      <a>查看</a>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="24">
                    <a-form-item label="收货清单">
                      <a-table
                        :columns="columns"
                        :data-source="calculate(ele.deliverVoList)"
                        :pagination="false"
                        rowKey="skuId"
                      >
                        <template slot="goodsSn" slot-scope="text, record">
                          <span v-if="record.skuName === '合计'"></span>
                          <span v-else>{{ text ? text : "-" }}</span>
                        </template>
                      </a-table>
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-form>
        </a-tab-pane>
      </a-tabs>
    </section>
  </a-layout>
</template>

<script>
import BreadcrumbItem from "@/components/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb";
import { checkDeliverBill } from "@/services/OrderManageService";
import { changeNumToString } from "@/components/utils";
import { tsFormat } from "@/components/DateUtils";
import { fetchReceiveBillBySaleBill } from "@/services/OrderService";

const columns = [
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "25%"
  },
  {
    title: "商品编号",
    dataIndex: "skuCode",
    width: "15%"
  },
  {
    title: "商品序列号",
    dataIndex: "goodsSn",
    scopedSlots: { customRender: "goodsSn" },
    width: "15%"
  },
  {
    title: "出厂单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "15%"
  },
  {
    title: "采购价（元）",
    dataIndex: "skuPurchasePrice",
    width: "15%"
  },
  {
    title: "商品数量",
    dataIndex: "quantity",
    width: "15%"
  }
];

export default {
  name: "DeliverReceiveDetail",
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      orderSn: this.$route.params.orderSn,
      type: this.$route.params.type,
      producerCorporationName: this.$route.params.producerCorporationName,
      purchaserCorporationName: this.$route.params.purchaserCorporationName,
      deliver: "",
      receiver: "",
      deliverList: [],
      changeNumToString,
      columns,
      loading: false,
      tsFormat
    };
  },
  mounted() {
    if (this.type === "custom") {
      this.deliver = "发货方（客户）:" + this.purchaserCorporationName;
      this.receiver = "收货方（商家）:" + this.producerCorporationName;
      this.loadCustomDeliverBill();
    } else {
      this.deliver = "发货方（商家）:" + this.producerCorporationName;
      this.receiver = "收货方（客户）:" + this.purchaserCorporationName;
      this.loadSellerDeliverBill();
    }
  },
  watch: {
    type(newVal) {
      if (newVal === "custom") {
        this.deliver = "发货方（客户）:" + this.purchaserCorporationName;
        this.receiver = "收货方（商家）:" + this.producerCorporationName;
        this.loadCustomDeliverBill();
      } else {
        this.deliver = "发货方（商家）:" + this.producerCorporationName;
        this.receiver = "收货方（客户）:" + this.purchaserCorporationName;
        this.loadSellerDeliverBill();
      }
    }
  },
  methods: {
    // 返回
    onBack() {
      this.$router.go(-1);
    },
    // 切换
    onSwitch() {
      if (this.type === "custom") {
        this.type = "seller";
      } else {
        this.type = "custom";
      }
    },
    // 查询客户的发货单
    loadCustomDeliverBill() {
      this.loading = true;
      checkDeliverBill(this.orderSn, "").then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data && data.length > 0) {
            this.deliverList = data;
            this.deliverList.forEach(ele => {
              this.loadReceiveBill(ele.consignmentId);
            });
          }
        }
      });
    },
    // 查询商家的发货单
    loadSellerDeliverBill() {
      this.loading = true;
      checkDeliverBill(this.orderSn, "producer").then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data && data.length > 0) {
            this.deliverList = data;
            this.deliverList.forEach(ele => {
              this.loadReceiveBill(ele.consignmentId);
            });
          }
        }
      });
    },
    // 获取收货单
    loadReceiveBill(consignmentId) {
      fetchReceiveBillBySaleBill(consignmentId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          let index = this.deliverList.findIndex(
            ele => ele.consignmentId === consignmentId
          );
          this.deliverList[index].receiveBill = data;
          this.deliverList = [...this.deliverList];
        }
      });
    },
    calculate(list) {
      let arr = [...list];
      if (arr && arr.length > 0) {
        let factoryPrice = 0;
        let purchasePrice = 0;
        let quantity = 0;
        arr.forEach(item => {
          factoryPrice = item.skuFactoryPrice * item.quantity + factoryPrice;
          purchasePrice = item.skuPurchasePrice * item.quantity + purchasePrice;
          quantity = quantity + item.quantity;
        });
        arr.push({
          skuName: "合计",
          skuFactoryPrice: factoryPrice.toFixed(2),
          skuPurchasePrice: purchasePrice.toFixed(2),
          quantity: quantity
        });
      }
      return arr;
    }
  }
};
</script>

<style scoped>
.card__container {
  min-height: 600px;
}

.switch__container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon__switch {
  margin: 0 10px;
  color: #4368f6;
  cursor: pointer;
}

/deep/ .ant-form-item {
  margin: 10px 40px 0 0;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 85px !important;
  text-align: left !important;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
  font-size: 14px;
}
</style>
